<template>
  <NuxtLink
    :to="path"
    :class="{ [`category--${size}`]: size }"
    class="category"
    no-prefetch
  >
    <AImage
      :src="item.src"
      :mobile-src="item.mobileSrc"
      :alt="item.title"
      :width="size ? imgSizes[size][0] : 136"
      :height="size ? imgSizes[size][1] : 200"
      :with-retina="withPixelRatio"
      :with-webp="withWebpImg"
      provider="public"
    />
    <div class="category__title">
      {{ item.title }}
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { computed, defineComponent } from 'vue'
import { useNavigate } from '@/composables/useNavigate'
import type { PropType } from 'vue'
import type { Category, CategorySize } from '@/components/atoms/Category/types'

import AImage from '@/components/atoms/Image/AImage.vue'

defineComponent({ name: 'ACategory' })

const props = defineProps({
  item: {
    type: Object as PropType<Category>,
    required: true
  },
  size: {
    type: String as PropType<CategorySize>,
    default: undefined
  },
  withPixelRatio: {
    type: Boolean,
    default: false
  },
  withWebpImg: {
    type: Boolean,
    default: false
  }
})

const { getUrl } = useNavigate()

const path = computed(() => getUrl(props.item.path, (!props.item.path?.endsWith?.('.html') && !props.item.path?.includes?.('?'))))

const imgSizes = {
  lg: [112, 96],
  base: [208, 208],
  sm: [152, 152],
  xs: [136, 200]
}
</script>

<style lang="postcss">
.category {
  --category-img-width: 136px;
  --category-img-height: 200px;
  --category-gap: 0;
  --category-title-position: absolute;
  --category-border-radius: var(--border-radius-base);
  --category-title-align: left;
  --category-overflow: hidden;

  position: relative;
  display: inline-flex;
  flex-direction: column;
  gap: var(--category-gap);
  align-items: center;
  justify-content: center;
  overflow: var(--category-overflow);
  width: var(--category-img-width);
  border-radius: var(--category-border-radius);
  cursor: pointer;
  mask-image: radial-gradient(white, black);

  & .category__title {
    position: var(--category-title-position);
    top: 148px;
    right: var(--spacer-2xs);
    left: var(--spacer-2xs);
    z-index: 1;
    display: -webkit-box;
    overflow: hidden;
    color: var(--color-text-dark);
    text-align: var(--category-title-align);
    text-overflow: ellipsis;
    white-space: break-spaces;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  & > .image {
    width: var(--category-img-width);
    height: var(--category-img-height);
    transition: transform 0.3s linear;

    & img {
      object-fit: contain;
      height: 100%;
      mix-blend-mode: multiply;
    }
  }

  @mixin text-sm-bold;
}

@media (hover: hover) and (--screen-lg) {
  .category:hover {
    & .category__title {
      color: var(--color-info-dark);
    }

    & > .image {
      transform: scale(1.05);
    }
  }
}

.category--sm {
  --category-img-width: 152px;
  --category-img-height: 152px;
  --category-gap: var(--spacer-xs);
  --category-title-position: static;
  --category-border-radius: 0;
  --category-title-align: center;
  --category-overflow: visible;

  @mixin text-base-bold;
}

.category--base {
  --category-img-width: 208px;
  --category-img-height: 208px;
  --category-gap: var(--spacer-sm);
  --category-title-position: static;
  --category-border-radius: 0;
  --category-title-align: center;
  --category-overflow: visible;

  @mixin text-xl;
}

.category--lg {
  --category-img-width: 112px;
  --category-img-height: 96px;
  --category-title-position: static;
  --category-gradient-color: #fdf2c5;

  width: 100%;
  height: 7.5rem;
  padding: var(--spacer-base);
  background-color: var(--color-neutral-50);
  background-image:
    linear-gradient(
      120deg,
      rgb(255 255 255 / 0%) 60%,
      var(--category-gradient-color) 90%
    );

  & .category__title {
    width: 100%;
  }

  & > .image {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  @mixin text-xl;
}
</style>
